import { WebappSurface } from "@clockwise/schema";
import { getSearchParamValue } from "../../util/getSearchParamValue";

export const getLocationHashValue = (location = window.location) => {
  const { hash } = location;
  return hash.replace(/^#/, "");
};

const highlightSearchParam = () => getSearchParamValue("highlight");

export const shouldHighlightSurface = (surface: WebappSurface | string) =>
  highlightSearchParam()?.toLowerCase() === surface.toLowerCase() ||
  (highlightSearchParam() === "true" &&
    getLocationHashValue().toLowerCase() === surface.toLowerCase());

export const shouldHighlightBreaks = () => highlightSearchParam() === WebappSurface.Breaks;

export const shouldHighlightFocusTime = () => highlightSearchParam() === WebappSurface.FocusTime;

export const shouldHighlightFlexibleMeetings = () =>
  highlightSearchParam() === WebappSurface.FlexibleMeetings;

export const shouldHighlightAutoDeclineOption = () => highlightSearchParam() === "AutoDecline";
