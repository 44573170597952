//////////////////
// IMPORTS
//////////////////
import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { EXPERIMENT_FLAGS } from "@clockwise/client-commons/src/util/experiment";

// TODO:
// if we import this file into chrome-extension, we will initialize some local storage flags that chrome-extension doesn't need
// like EXPERIMENT_FLAGS. luckily this isn't an issue because chrome-extension implements its own local-storage util
// but it is still pretty lame
// SOLUTION:
// chrome-extension should not implement its own local-storage util when this exists. then each client should
// instantiate its own local storage with the flags it needs

///////////////////////
// STORE SETUP
//////////////////////
let localStorageAvailable = false;

try {
  localStorage.getItem("SocketId");
  localStorageAvailable = true;
} catch (error) {
  // we're inside an iFrame with the setting to block
  // 3rd party cookies... sigh
  localStorageAvailable = false;
}

// if localStorage isn't accessible, we need an in-memory replacement
class MemoryStore {
  private store: { [key: string]: string } = {};

  public clear(): void {
    this.store = {};
  }

  public getItem(key: string): string | null {
    return this.store[key] || null;
  }

  public removeItem(key: string): void {
    delete this.store[key];
  }

  public setItem(key: string, data: string): void {
    this.store[key] = data;
  }
}

const localStore = localStorageAvailable ? localStorage : new MemoryStore();

export function isLocalStorageAvailable() {
  return localStorageAvailable;
}

///////////////////////
// BASE CLASSES
//////////////////////

// basic class to set / get / etc  for a given localstorage key
class LocalStorage {
  private key: string;
  constructor(key: string) {
    this.key = key;
  }

  public set(value: string) {
    if (!value) {
      console.error(
        "Attempted to set local storage value to undefined/null/empty.  Use remove instead.",
      );
    }
    localStore.setItem(this.key, value);
  }

  public get() {
    return localStore.getItem(this.key);
  }

  public remove() {
    localStore.removeItem(this.key);
  }
}

// basic class to set / get / etc  for a given localstorage key
// class LocalStorageObject<ObjectType> {
//   private key: string;
//   constructor(key: string) {
//     this.key = key;
//   }

//   public set(value: ObjectType) {
//     if (!value) {
//       logger.error('Attempted to set local storage value to undefined/null/empty.  Use remove instead.');
//     }
//     localStore.setItem(this.key, JSON.stringify(value));
//   }

//   public get(): ObjectType | null {
//     const json = localStore.getItem(this.key);
//     return !!json && JSON.parse(json) || null;
//   }

//   public remove() {
//     localStore.removeItem(this.key);
//   }
// }

class LocalStorageCounter {
  private key: string;
  constructor(
    key: string,
    startingValue?: number,
    private min: number = -Infinity,
    private max: number = Infinity,
  ) {
    this.key = key;

    if (startingValue !== undefined && localStore.getItem(this.key) === null) {
      this.set(startingValue);
    }
  }

  private bound(value: number) {
    return Math.max(this.min, Math.min(this.max, value));
  }

  public set(value: number) {
    if (typeof value !== "number") {
      console.error("Attempted to set local storage counter with a non-number type");
    }
    localStore.setItem(this.key, this.bound(value).toString());
  }

  public increment() {
    this.set(this.get() + 1);
  }

  public decrement() {
    this.set(this.get() - 1);
  }

  public get() {
    try {
      return parseInt(localStore.getItem(this.key) || "0");
    } catch (_e) {
      return 0;
    }
  }

  public remove() {
    localStore.removeItem(this.key);
  }
}

class LocalStorageStack<T> {
  private key: string;
  constructor(key: string, startingValue?: T[], private maxSize = 5) {
    this.key = key;

    if (startingValue !== undefined && localStore.getItem(this.key) === null) {
      this.set(startingValue);
    }
  }

  public set(value: T[]) {
    localStore.setItem(
      this.key,
      JSON.stringify(value.slice(value.length - this.maxSize, value.length)),
    );
  }

  public get(): T[] {
    try {
      return JSON.parse(localStore.getItem(this.key) || "[]");
    } catch (_e) {
      return [];
    }
  }

  public push(value: T) {
    this.set([...this.get(), value]);
  }

  public remove() {
    localStore.removeItem(this.key);
  }
}

// for usage with booleans
export class LocalStorageBoolean {
  private key: string;
  constructor(key: string, startingValue?: boolean) {
    this.key = key;

    if (startingValue !== undefined && this.isNull()) {
      this.set(startingValue);
    }
  }

  public set(value: boolean) {
    if (typeof value !== "boolean") {
      console.error("Attempted to set local storage boolean with a non-boolean type");
    }
    localStore.setItem(this.key, value.toString());
  }

  public get() {
    return localStore.getItem(this.key) === "true";
  }

  public remove() {
    localStore.removeItem(this.key);
  }

  public isNull() {
    // Note: This is equivalent to `key` not existing in local storage
    return localStore.getItem(this.key) === null;
  }
}

class LocalStorageWithCallbacks extends LocalStorage {
  private callbacks: ((value: string | null) => void)[];

  constructor(key: string) {
    super(key);

    this.callbacks = [];
  }

  public onChange(callback: (value: string | null) => void) {
    this.callbacks.push(callback);
  }

  public set(value: string) {
    super.set(value);
    this.callbacks.forEach((callback) => callback(value));
  }

  public remove() {
    super.remove();
    this.callbacks.forEach((callback) => callback(null));
  }
}

/*
// base class for socket
class LocalStorageWithSocket extends LocalStorage {
  private emitOnSet: string;
  private emitOnRemove: string;

  constructor(key: string, emitOnSet: string, emitOnRemove: string) {
    super(key);
    this.emitOnSet = emitOnSet;
    this.emitOnRemove = emitOnRemove;
  }

  public set(value: string) {
    super.set(value);
    // for jwts - socket.io-client is on the cusp of supporting extraHeaders where we could send an auth header instead of this
    // in the interim, if we ever set jwt state, update the server socket state to be associated with this jwt
    socket.get().emit(this.emitOnSet, value);
  }

  public remove() {
    super.remove();
    // also remove from server socket state
    socket.get().emit(this.emitOnRemove);
  }
}
*/

// date based class to set / get
class LocalStorageDate {
  private key: string;
  private defaultGetToNow: boolean;
  constructor(key: string, defaultGetToNow = true) {
    this.key = key;
    this.defaultGetToNow = defaultGetToNow;
  }

  public set(date: Date | ZonedMoment) {
    localStore.setItem(this.key, date.valueOf().toString());
  }

  public get() {
    const stringMillis = localStore.getItem(this.key);
    const millis = !!stringMillis && Number(stringMillis);
    const defaultDate = this.defaultGetToNow ? new ZonedMoment() : undefined;
    return (!!millis && new ZonedMoment(millis)) || defaultDate;
  }

  public remove() {
    localStore.removeItem(this.key);
  }
}

// class LocalStorageWithLoader extends LocalStorage {
//   private loader: () => string;
//   constructor(key: string, loader: () => string) {
//     super(key);
//     this.loader = loader;
//   }

//   public get() {
//     const storedValue = super.get();

//     if (storedValue) {
//       return storedValue;
//     }

//     // otherwise save the generated value and return it
//     const newValue = this.loader();
//     this.set(newValue);
//     return newValue;
//   }
// }

// base class for a string to any map
export class LocalStorageMap {
  private key: string;

  constructor(key: string) {
    this.key = key;
    this.initObj();
    this.getAll = this.getAll.bind(this);
    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
  }

  private initObj() {
    const map = this.getAll();
    localStore.setItem(this.key, JSON.stringify(map));
  }

  public clear(): void {
    localStore.setItem(this.key, JSON.stringify({}));
  }

  public getAll(): { [k: string]: any } {
    try {
      const json = localStore.getItem(this.key);
      const map = json ? JSON.parse(json) : {};
      return typeof map === "object" ? map : {};
    } catch (e) {
      return {};
    }
  }

  public set(subKey: string, value: any): void {
    let map = this.getAll();
    if (!map) {
      map = {};
    }
    map[subKey] = value;
    localStore.setItem(this.key, JSON.stringify(map));
  }

  public get(subKey: string): any {
    const map = this.getAll();
    return !!map && !!map[subKey] ? map[subKey] : null;
  }
}

// base class for boolean flags
export class LocalStorageFlags {
  private key: string;
  private flags: { [k: string]: string };

  constructor(key: string, flags: { [k: string]: string }) {
    this.key = key;
    this.flags = flags;
    this.initObj();
  }

  private initObj = () => {
    const storedFlags = this.getAll();

    const flags = Object.keys(this.flags).reduce((accum: { [k: string]: boolean }, flag) => {
      const flagKey = this.flags[flag];
      const localStoreValue = storedFlags && storedFlags[flagKey];
      accum[flagKey] = !!localStoreValue;
      return accum;
    }, {});
    localStore.setItem(this.key, JSON.stringify(flags));
  };

  public getAll = (): { [k: string]: boolean } | null => {
    try {
      const json = localStore.getItem(this.key);
      return json ? JSON.parse(json) : null;
    } catch (e) {
      return null;
    }
  };

  public toggleFlag = (subKey: string): void => {
    const currentValue = this.getFlag(subKey);
    this.setFlag(subKey, !currentValue);
  };

  public setFlag = (subKey: string, value: boolean): void => {
    const flags = this.getAll();
    if (!!flags && typeof flags[subKey] === "boolean") {
      flags[subKey] = value;
      localStore.setItem(this.key, JSON.stringify(flags));
    }
  };

  // HACK: even though this accepts boolean | number | string, flag checks will only work for boolean types
  public setAll = (flags: { [key: string]: boolean | number | string }) => {
    localStore.setItem(this.key, JSON.stringify(flags));
  };

  public getFlag = (subKey: string): boolean => {
    const flags = this.getAll();
    return !!flags && !!flags[subKey];
  };
}

// number
class LocalStorageNumber {
  private key: string;
  constructor(key: string) {
    this.key = key;
  }

  public set(value: number) {
    if (typeof value !== "number") {
      console.error("Attempted to set local storage number to a value other than a number.");
    }
    localStore.setItem(this.key, value.toString());
  }

  public get() {
    const stringNumber = localStore.getItem(this.key);
    return stringNumber ? Number(stringNumber) : null;
  }

  public remove() {
    localStore.removeItem(this.key);
  }
}

//////////////////
// HELPERS
//////////////////

///////////////////////
// EXPERIMENT FLAGS
//////////////////////
const EXPERIMENTS = "Experiments";
const CACHED_EXPERIMENTS = "CachedExperiments";
// kind of awkward that it sets up a different interface, but whatever
export const experiments = new LocalStorageFlags(EXPERIMENTS, EXPERIMENT_FLAGS);
export const cachedExperiments = new LocalStorageFlags(CACHED_EXPERIMENTS, EXPERIMENT_FLAGS);

/////////////////////////////////////
// ACTUAL LOCAL STORAGE INTERFACES
////////////////////////////////////

// socket_id for connected socket.io
const SOCKET_ID = "SocketId";
export const socketId = new LocalStorage(SOCKET_ID);

// authenticated jwt for auth/login
const JWT = "Jwt";
export const jwt = new LocalStorageWithCallbacks(JWT);

// current org
const XSRF = "Xsrf";
export const xsrf = new LocalStorageWithCallbacks(XSRF);

// jwt token before sudo'ing
const SUDO_JWT = "SudoJwt";
export const sudoJwt = new LocalStorage(SUDO_JWT);

// current org
const ORG_ID = "OrgId";
export const orgId = new LocalStorage(ORG_ID);

// current org primary email
const ORG_PRIMARY_EMAIL = "OrgPrimaryEmail";
export const orgPrimaryEmail = new LocalStorage(ORG_PRIMARY_EMAIL);

// last defrag card timestamp
const LAST_DEFRAG_CARD_TIMESTAMP = "LastDefragCardTimestamp";
export const lastDefragCardTimestamp = new LocalStorage(LAST_DEFRAG_CARD_TIMESTAMP);

// last autopilot suggestions card timestamp
const LAST_AUTOPILOT_SUGGESTIONS_CARD_TIMESTAMP = "LastAutopilotSuggestionsCardTimestamp";
export const lastAutopilotSuggestionsCardTimestamp = new LocalStorage(
  LAST_AUTOPILOT_SUGGESTIONS_CARD_TIMESTAMP,
);

// confetti!
const SEEN_CONFETTI = "SeenConfetti";
export const seenConfetti = new LocalStorage(SEEN_CONFETTI);
const LAST_SEEN_CONFETTI = "LastSeenConfetti";
export const lastSeenConfetti = new LocalStorageDate(LAST_SEEN_CONFETTI, false);

// render time zone
const RENDER_TIME_ZONE_MAP = "RenderTimeZoneMap";
export const renderTimeZoneMap = new LocalStorageMap(RENDER_TIME_ZONE_MAP);

// current time zone
const CURRENT_TIME_ZONE_MAP = "CurrentTimeZoneMap";
export const currentTimeZoneMap = new LocalStorageMap(CURRENT_TIME_ZONE_MAP);

// has seen login
const HAS_SEEN_LOGIN_PAGE = "HasSeenLoginPage";
export const hasSeenLoginPage = new LocalStorageBoolean(HAS_SEEN_LOGIN_PAGE);

// analytics user id
const ANALYTICS_USER = "AnalyticsUser";
export const analyticsUser = new LocalStorage(ANALYTICS_USER);

// analytics pre id
const ANALYTICS_PRE = "AnalyticsPre";
export const analyticsPre = new LocalStorage(ANALYTICS_PRE);

// debugging feed suggestions
const SUGGESTIONS_DEBUG_FETCH_DATE = "SuggestionsDebugFetchDate";
export const suggestionsDebugFetchDate = new LocalStorageDate(SUGGESTIONS_DEBUG_FETCH_DATE, false);

// tracking the number of suggestions in the feed
const SUGGESTIONS_COUNT = "SuggestionsCount";
export const suggestionsCount = new LocalStorageNumber(SUGGESTIONS_COUNT);

// slack oauth nonce
const SLACK_NONCE = "SlackNonce";
export const slackNonce = new LocalStorage(SLACK_NONCE);

// slack oauth nonce
const SLACK_NONCE_FOR_TEAM = "SlackNonceForTeam";
export const slackNonceForTeam = new LocalStorage(SLACK_NONCE_FOR_TEAM);

// zoom account level oauth nonce
const ZOOM_NONCE = "ZoomNonce";
export const zoomNonce = new LocalStorage(ZOOM_NONCE);

// zoom user level oauth nonce
const ZOOM_USER_LEVEL_NONCE = "ZoomUserLevelNonce";
export const zoomUserLevelNonce = new LocalStorage(ZOOM_USER_LEVEL_NONCE);

// M365 oauth nonce
const M365_NONCE = "M365Nonce";
export const m365Nonce = new LocalStorage(M365_NONCE);

// Google oauth nonce
const GOOGLE_AUTH_NONCE = "GoogleAuthNonce";
export const googleAuthNonce = new LocalStorage(GOOGLE_AUTH_NONCE);

// set on successful login, never unset
const CW_USER = "CwUser";
export const cwUser = new LocalStorage(CW_USER);

// internal flag, for bypassing health.json
const CW_INTERNAL = "CwInternal";
export const cwInternal = new LocalStorage(CW_INTERNAL);

// pause date for week in review card
// note that this just stores the string for the week (e.g., '2018-07-30') that has been paused
// this value gets overwritten, which only works so long as there's only one week-in-review card at once
const WEEK_IN_REVIEW_PAUSED = "WEEK_IN_REVIEW_PAUSED";
export const weekInReviewPaused = new LocalStorage(WEEK_IN_REVIEW_PAUSED);

// chrome webstore review response
const CHROME_WEBSTORE_REVIEW_RESPONSE_DATE = "ChromeWebstoreReviewResponseDate";
export const chromeWebstoreReviewResponseDate = new LocalStorageDate(
  CHROME_WEBSTORE_REVIEW_RESPONSE_DATE,
  false,
);

const AUTOPILOT_WEEKLY_SUMMARY_SURVEY_RESPONSE_DATE = "AutopilotWeeklySummarySurveyResponseDate";
export const autopilotWeeklySummarySurveyResponseDate = new LocalStorageDate(
  AUTOPILOT_WEEKLY_SUMMARY_SURVEY_RESPONSE_DATE,
  false,
);

const ONBOARDING_SHOULD_INJECT_TEAMS_AT_END = "OnboardingShouldInjectTeamsAtEnd";
export const onboardingShouldInjectTeamsAtEnd = new LocalStorageBoolean(
  ONBOARDING_SHOULD_INJECT_TEAMS_AT_END,
);

const PAID_PROMO_BAR_COLLAPSED = "PaidPromoBarCollapsed";
export const paidPromoBarCollapsed = new LocalStorageBoolean(PAID_PROMO_BAR_COLLAPSED);

const NETWORK_FAILURE_SCALE = "NetworkFailureScale";
export const networkFailureScale = new LocalStorageCounter(NETWORK_FAILURE_SCALE, 0, 0, 10);

const LAST_UPDATED_MILLIS = "LastUpdatedMillis";
export const lastUpdatedMillis = new LocalStorageStack<number>(LAST_UPDATED_MILLIS, [], 2);

const USER_DEACTIVATED = "UserDeactivated";
export const userDeactivated = new LocalStorageBoolean(USER_DEACTIVATED, false);

const SCHEDULING_LINK_BOOKED_NAME = "SchedulingLinkBookedName";
export const schedulingLinkBookedName = new LocalStorage(SCHEDULING_LINK_BOOKED_NAME);

const SCHEDULING_LINK_BOOKED_EMAIL = "SchedulingLinkBookedEmail";
export const schedulingLinkBookedEmail = new LocalStorage(SCHEDULING_LINK_BOOKED_EMAIL);

const HAS_RESET_SCHEDULING_LINK_BOOKED_EMAIL = "HasResetSchedulingLinkBookedEmail";
export const hasResetSchedulingLinkBookedEmail = new LocalStorageBoolean(
  HAS_RESET_SCHEDULING_LINK_BOOKED_EMAIL,
);

const ONBOARDING_META = "OnboardingMeta";
export const onboardingMeta = new LocalStorageMap(ONBOARDING_META);

const CW_ECOSYSTEM = "CW_ECOSYSTEM";
export const cwEcosystem = new LocalStorage(CW_ECOSYSTEM);

const LAST_CHROME_WRAPPER_MOUNT_TIMESTAMP = "LastChromeWrapperMount";
export const lastChromeWrapperMountTimestamp = new LocalStorageDate(
  LAST_CHROME_WRAPPER_MOUNT_TIMESTAMP,
  false,
);

const LAST_TAB_FOCUS_TIMESTAMP = "LastExtensionTabFocus";
export const lastExtensionTabFocusTimestamp = new LocalStorageDate(LAST_TAB_FOCUS_TIMESTAMP, false);

const ONBOARDING_CHECKLIST = "OnboardingChecklist";
export const onboardingChecklist = new LocalStorageMap(ONBOARDING_CHECKLIST);

const ENVIRONMENT_OVERRIDE = "EnvironmentOverride";
export const environmentOverride = new LocalStorage(ENVIRONMENT_OVERRIDE);

const DEVENV_OVERRIDE = "DevenvOverride";
export const devenvOverride = new LocalStorage(DEVENV_OVERRIDE);

const EXTENSION_POPPED_OPEN_TIMESTAMP = "ExtensionPoppedOpenTimestamp";
export const extensionPoppedOpenTimestamp = new LocalStorage(EXTENSION_POPPED_OPEN_TIMESTAMP);

const LAST_SIGN_IN_DATE = "LastSignInDate";
export const lastSignInDate = new LocalStorage(LAST_SIGN_IN_DATE);

const SIGNUP_EXPERIMENT_EXTENSION_REDIRECT = "SignupExperimentExtensionRedirect";
export const signupExperimentExtensionRedirect = new LocalStorageBoolean(
  SIGNUP_EXPERIMENT_EXTENSION_REDIRECT,
);

const BROWSER_NOTIFICATION_BANNER_DISMISS_TIMESTAMPS = "BrowserNotificationBannerDismissTimestamps";
export const browserNotificationBannerDismissTimestamps = new LocalStorageMap(
  BROWSER_NOTIFICATION_BANNER_DISMISS_TIMESTAMPS,
);

// service worker
const PUSH_NOTIFICATION_EVENT_FLEXIBILITY_SUGGESTION = "PushNotificationEventFlexibilitySuggestion";
export const pushNotificationEventFlexibilitySuggestion = new LocalStorageMap(
  PUSH_NOTIFICATION_EVENT_FLEXIBILITY_SUGGESTION,
);

export const HOME_NAV_COLLAPSED = "HomeNavCollapsed";
export const homeNavCollapsed = new LocalStorageBoolean(HOME_NAV_COLLAPSED);

export const INVITE_LINK = "InviteLink";
export const inviteLink = new LocalStorageMap(INVITE_LINK);

export const CW_NEW_BANNER_DISMISSED = "ClockwiseWebNewPromoBanner";
export const clockwiseWebNewBannerDismissed = new LocalStorageBoolean(CW_NEW_BANNER_DISMISSED);

export const SIGNUP_EXPERIMENT_INITIAL_ANONYMOUS_ID = "SignupExperimentInitialAnonymousId";
export const signupExperimentInitialAnonymousId = new LocalStorage(
  SIGNUP_EXPERIMENT_INITIAL_ANONYMOUS_ID,
);

export const LAST_ASKED_DAY_POSITIVITY_PROMPT = "LastAskedDayPositivityPrompt";
export const lastAskedDayPositivityPrompt = new LocalStorage(LAST_ASKED_DAY_POSITIVITY_PROMPT);

const NEW_USER_SIGNUP_VIA_SHARED_PROPOSAL = "NewUserSignupViaSharedProposal";
export const newUserSignedupViaSharedProposal = new LocalStorageBoolean(
  NEW_USER_SIGNUP_VIA_SHARED_PROPOSAL,
);

export const SHOW_WEEKS_WORK_HOUR_BOUNDARIES = "ShowWeeksWorkHourBoundaries";
export const showWeeksWorkHourBoundaries = new LocalStorageBoolean(SHOW_WEEKS_WORK_HOUR_BOUNDARIES);
