import { NavEducationStep } from "#webapp/src/components/superpowers-checklist/useNuxPopoverStates.util";
import { UpdateUserFlowStateDocument } from "#webapp/src/graphql/__generated__/UpdateUserFlowState.generated";
import { ApolloQueryResult, useMutation, useQuery } from "@apollo/client";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { transform } from "@clockwise/web-commons/src/util/transform.util";
import { find, noop } from "lodash";
import { compact, map } from "lodash/fp";
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFeatureFlag } from "../../launch-darkly";
import { isFlowStateFinished, isFlowStateUnfinished } from "../../util/flow-state.util";
import { getCurrentOrg } from "../../util/org.util";
import {
  CurrentFlowStateDocument,
  CurrentFlowStateQuery,
} from "../__generated__/CurrentFlowState.generated";

export type OnboardingContextType = ReturnType<typeof useOnboardingForProvider>;

export const defaultOnboardingContext = {
  refetchOnboardingFlowStates: () =>
    new Promise<ApolloQueryResult<CurrentFlowStateQuery>>(() => null),
  currentWebOnboardingFlowState: null,
  shouldRenderOnboarding: false,
  setShouldRenderOnboarding: noop,
  loading: false,
  orgId: null,
  shouldRedirectApp: true,
  shouldRenderDeprecatedOnboarding: false,
  shouldRenderWebOnboarding: false,
};
export const OnboardingContext = createContext<OnboardingContextType>(defaultOnboardingContext);

export const useOnboarding = (): OnboardingContextType => {
  const context = useContext(OnboardingContext);
  return context;
};

export enum OnboardingFlowState {
  InitialOnboarding = "InitialOnboarding",
  WebOnboarding = "WebOnboarding",
  ChecklistFlexMeetings = "ChecklistFlexMeetings",
  ChecklistHolds = "ChecklistHolds",
  ChecklistPreferences = "ChecklistPreferences",
  ChecklistScheduleMeeting = "ChecklistScheduleMeeting",
  AIChecklistClosed = "AIChecklistClosed",
  NavigationEducation = "NavigationEducation",
}

type FLowStateEdgeType = ({
  __typename: "FlowStateEdge";
  node: FlowStateType;
} | null)[];

export type FlowStateType = {
  __typename: "FlowState";
  id: string;
  flowKey: string | null;
  previousState: string | null;
  current: {
    __typename: "FlowStatus";
    id: string;
    state: string | null;
    percentComplete: number | null;
    errorMessage: string | null;
  } | null;
  pending: {
    __typename: "FlowStatus";
    id: string;
    state: string | null;
    percentComplete: number | null;
    errorMessage: string | null;
  } | null;
} | null;

export const getWebOnboardingFlowState = (flowState?: FlowStateType) => {
  return flowState?.current?.state ?? null;
};

export const getNuxStatesFromFlowStates = (
  userFlowStates: FLowStateEdgeType,
  orgFlowStates: FLowStateEdgeType,
) => {
  const flowStates = [...userFlowStates, ...orgFlowStates];

  const getFlowState = (flowKey: OnboardingFlowState) =>
    transform(flowStates, compact, map("node"), (s) => find(s, { flowKey }) ?? null);

  const newDeprecatedOnboardingState = getFlowState(OnboardingFlowState.InitialOnboarding);
  const newWebOnboardingState = getFlowState(OnboardingFlowState.WebOnboarding);
  const navEducationState = getFlowState(OnboardingFlowState.NavigationEducation);

  const nuxChecklistFlowStates = [
    getFlowState(OnboardingFlowState.ChecklistFlexMeetings),
    getFlowState(OnboardingFlowState.ChecklistPreferences),
    getFlowState(OnboardingFlowState.ChecklistHolds),
  ];

  const anyNuxChecklistItemsUnfinished = nuxChecklistFlowStates
    .map((item: FlowStateType) => isFlowStateFinished(item)) //false,
    .some((isFinished: boolean) => isFinished === false);

  return {
    webOnboardingState: newWebOnboardingState,
    deprecatedOnboardingState: newDeprecatedOnboardingState,
    nuxChecklistFinished: !anyNuxChecklistItemsUnfinished,
    navEducationState: navEducationState,
  };
};

export const getShouldRenderOnboarding = (
  webOnboardingState: FlowStateType | null,
  deprecatedOnboardingState: FlowStateType | null,
) => {
  // We allow user to respond to shared proposal without onboarding
  const onSharedProposalPage =
    window.location.pathname.includes("/app/proposal/") || window.location.pathname.includes("/p/");

  // If states aren't fetched don't render onboarding
  if (!webOnboardingState || !deprecatedOnboardingState) {
    return false;
  }

  // If user is on (public) shared proposal page, don't render onboarding
  if (onSharedProposalPage) {
    return false;
  }

  // If the user has completed any onboarding, they are considered onboarded
  // This makes resetting difficult for now but is the desired cutover/rollout state
  // We don't want to force onboard any user who has completed an onboarding in the past
  const isUserOnboarded =
    isFlowStateFinished(webOnboardingState) || isFlowStateFinished(deprecatedOnboardingState);
  if (isUserOnboarded) {
    return false;
  }

  const isDeprecatedOnboardingIncomplete = isFlowStateUnfinished(deprecatedOnboardingState);
  const isWebOnboardingIncomplete = isFlowStateUnfinished(webOnboardingState);

  return isDeprecatedOnboardingIncomplete || isWebOnboardingIncomplete;
};

export const useOnboardingForProvider = ({ orgId }: { orgId: string | null }) => {
  const track = useTracking();
  const { data, refetch: refetchOnboardingFlowStates, loading } = useQuery<CurrentFlowStateQuery>(
    CurrentFlowStateDocument,
    { skip: !orgId, fetchPolicy: "no-cache" },
  );
  const [updateFlowStateUser] = useMutation(UpdateUserFlowStateDocument);

  const [deprecatedOnboardingState, setDeprecatedOnboardingState] = useState<FlowStateType | null>(
    null,
  );
  const [webOnboardingState, setWebOnboardingState] = useState<FlowStateType | null>(null);
  const [shouldRenderOnboarding, setShouldRenderOnboarding] = useState(
    getShouldRenderOnboarding(webOnboardingState, deprecatedOnboardingState),
  );
  const [onNux2025] = useFeatureFlag("NUX-Q1-2025");

  // We allow user to respond to shared proposal without onboarding
  const onSharedProposalPage =
    window.location.pathname.includes("/app/proposal/") || window.location.pathname.includes("/p/");

  useEffect(() => {
    if (data) {
      const org = getCurrentOrg(data?.viewer);

      const userFlowStates = data?.viewer.user?.flowStates?.edges ?? [];
      const orgFlowStates = org?.flowStates?.edges ?? [];
      const states = getNuxStatesFromFlowStates(userFlowStates, orgFlowStates);

      setWebOnboardingState(states.webOnboardingState);
      setDeprecatedOnboardingState(states.deprecatedOnboardingState);

      const navEducationState = states.navEducationState?.current?.state;
      // Update the flow state while the user is in onboarding
      if (
        onNux2025 &&
        navEducationState === "Unstarted" &&
        getShouldRenderOnboarding(states.webOnboardingState, states.deprecatedOnboardingState)
      ) {
        track(TrackingEvents.NUX_2025.TOOLTIP_FLOW.TOOLTIP_STEP, {
          newState: `${NavEducationStep.IntroModal}`,
          oldState: `${navEducationState}`,
          isOnNux2025: true,
        });
        void updateFlowStateUser({
          variables: {
            input: {
              newState: NavEducationStep.IntroModal,
              flowKey: OnboardingFlowState.NavigationEducation,
            },
          },
        });
      }
    }
  }, [data, onNux2025]);

  const currentWebOnboardingFlowState = useMemo(() => {
    return getWebOnboardingFlowState(webOnboardingState);
  }, [webOnboardingState]);

  useEffect(() => {
    setShouldRenderOnboarding(
      getShouldRenderOnboarding(webOnboardingState, deprecatedOnboardingState),
    );
  }, [webOnboardingState, deprecatedOnboardingState, onSharedProposalPage]);

  const shouldRedirectApp = useMemo(() => {
    return !shouldRenderOnboarding;
  }, [shouldRenderOnboarding]);

  return useMemo(() => {
    return {
      refetchOnboardingFlowStates,
      currentWebOnboardingFlowState,
      shouldRenderOnboarding,
      setShouldRenderOnboarding,
      loading,
      orgId,
      shouldRedirectApp,
    };
  }, [
    refetchOnboardingFlowStates,
    currentWebOnboardingFlowState,
    shouldRenderOnboarding,
    setShouldRenderOnboarding,
    loading,
    orgId,
    shouldRedirectApp,
  ]);
};

interface Props {
  orgId: string | null;
}
export const OnboardingProvider: FC<PropsWithChildren<Props>> = ({ children, orgId }) => {
  const value = useOnboardingForProvider({ orgId });
  return <OnboardingContext.Provider value={value}> {children} </OnboardingContext.Provider>;
};
